<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <div slot="header">
        Order ID <strong>#{{ this.order_id }}</strong>
      </div>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
          <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
          </div>
        </div>
        <b-row class="mb-4">
          <b-col sm="4">
            <h6>Order Date</h6>
            <div class="mb-3">
              <strong>{{ this.order_date }}</strong>
            </div>
            <h6>Payment Date</h6>
            <div class="mb-3">
              <strong>{{
                this.payment_date === null ? "-" : this.payment_date
              }}</strong>
            </div>
            <h6>User</h6>
            <div class="mb-3">
              <strong>{{ this.users }}</strong>
            </div>
            <h6>Status Pembayaran</h6>
            <div class="mb-3">
              <strong>{{ this.setStatus(this.payment_status) }}</strong>
            </div>
            <h6>Metode Pembayaran</h6>
            <div class="mb-3">
              <strong>{{
                this.payment_name === null ? "-" : this.payment_name
              }}</strong>
            </div>
            <!-- <h6>Jasa Pengiriman</h6>
            <div class="mb-3">
              <strong>{{ this.shipping_provider }}</strong>
            </div> -->
            <h6>Biaya Admin</h6>
            <div class="mb-3">
              <strong>{{ this.admin_fee | currency }}</strong>
            </div>
          </b-col>
          <b-col sm="4">
            <h6>Nama Penerima</h6>
            <div class="mb-3">
              <strong>{{ this.receiver_name }}</strong>
            </div>
            <h6>Nomor Penerima</h6>
            <div class="mb-3">
              <strong>{{ this.phone_number }}</strong>
            </div>
            <h6>Alamat Lengkap Penerima</h6>
            <div class="mb-3">
              <strong>{{ this.address }}</strong>
            </div>

            <h6>Kecamatan</h6>
            <div class="mb-3">
              <strong>{{ this.district }}</strong>
            </div>
            <h6>Kelurahan</h6>
            <div class="mb-3">
              <strong>{{ this.village }}</strong>
            </div>
            <h6>Kode Pos</h6>
            <div class="mb-3">
              <strong>{{
                this.postal_code === null ? "-" : this.postal_code
              }}</strong>
            </div>
            <!-- <h6>Nomor Resi</h6>
            <div class="mb-3">
              <strong>{{
                this.tracking_no === null ? "-" : this.tracking_no
              }}</strong>
            </div> -->
          </b-col>
          <b-col sm="4">
            <h6>Pembelian Perhiasan</h6>
            <div class="mb-3">
              <strong>{{ this.total_price | currency }}</strong>
            </div>
            <h6>Pajak PPN 2%</h6>
            <div class="mb-3">
              <strong>{{ this.tax_ppn | currency }}</strong>
            </div>
            <h6>Pajak Beli Emas</h6>
            <div class="mb-3">
              <strong>{{ this.tax_pph | currency }}</strong>
            </div>
            <h6>Nilai Emas Kekurangan (Gram)</h6>
            <div class="mb-3">
              <strong>{{ this.amount_gold }}</strong>
            </div>
            <h6>Nilai Emas Kekurangan (IDR)</h6>
            <div class="mb-3">
              <strong>{{ this.amount_idr | currency }}</strong>
            </div>
            <h6>Total</h6>
            <div class="mb-3">
              <strong>{{ this.total | currency }}</strong>
            </div>
            <h6>Komisi</h6>
            <div class="mb-3">
              <strong>{{ this.commission | currency }}</strong>
            </div>
          </b-col>
        </b-row>
        <!-- <b-row>
          <b-col>
            <h6>Province</h6>
            <div class="mb-3">
              <strong>{{
                this.province === null ? "-" : this.province
              }}</strong>
            </div>
          </b-col>
          <b-col>
            <h6>City</h6>
            <div class="mb-3">
              <strong>{{ this.city === null ? "-" : this.city }}</strong>
            </div>
          </b-col>
        </b-row> -->
        <div class="table-responsive-sm">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Invoice Brand</th>
                <th>Brand</th>
                <th>Product</th>
                <th class="center">Quantity</th>
                <th class="center">Base Price</th>
                <th class="center">Discount Amount</th>
                <th class="right">Total</th>
                <th>Va Disbursement</th>
                <th>Status Disbursement</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <td class="left">
                  {{ item.invoice_number === null ? "-" : item.invoice_number }}
                </td>
                <td class="left">{{ item.brand }}</td>
                <td class="left">
                  <p v-for="(v, i) in item.items" :key="i">
                    <span>{{ v.name }}</span> <br />
                  </p>
                </td>
                <td class="left">
                  <p v-for="(v, i) in item.items" :key="i">
                    <span>{{ v.qty }}</span> <br />
                  </p>
                </td>
                <td class="left">
                  <p v-for="(v, i) in item.items" :key="i">
                    <span>{{ v.base_price | currency }}</span> <br />
                  </p>
                </td>
                <td class="left">
                  <p v-for="(v, i) in item.items" :key="i">
                    <span v-if="v.promotion_price === null">-</span>
                    <span v-else>{{ v.promotion_price | currency }}</span> <br />
                  </p>
                </td>
                <td class="left">
                  <p v-for="(v, i) in item.items" :key="i">
                    <span>{{ v.total | currency }}</span> <br />
                  </p>
                </td>
                <td class="left">
                  {{
                    item.virtual_account === null ? "-" : item.virtual_account
                  }}
                </td>
                <td class="left">
                  {{
                    item.disbursement_status === null
                      ? "-"
                      : item.disbursement_status
                  }}
                </td>
                <td class="center">
                  <b-button
                    variant="primary"
                    :to="{
                      name: 'Edit Jewelry Order',
                      params: { id: item.invoice_number },
                    }"
                    class="m-1"
                    type="button"
                    >Edit</b-button
                  >
                  <b-button class="m-1" variant="info" @click="openModal(item)"
                    >Detail</b-button
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <b-modal
          title="Detail Shipping"
          size="lg"
          v-model="modal"
          @ok="modal = false"
        >
          <h6>Jasa Pengiriman</h6>
          <div class="mb-3">
            <strong>{{ this.detailShipping.provider }}</strong>
          </div>
          <h6>Status Pengiriman</h6>
          <div class="mb-3">
            <strong>{{ this.detailShipping.status }}</strong>
          </div>
          <h6>Biaya Pengiriman</h6>
          <div class="mb-3">
            <strong>{{ this.detailShipping.shipping_cost | currency }}</strong>
          </div>
          <h6>Diskon Biaya Pengiriman</h6>
          <div class="mb-3">
            <strong>{{ this.detailShipping.free_shipping | currency }}</strong>
          </div>
        </b-modal>
        <b-row>
          <b-col lg="4" sm="5">
            <h6>Note :</h6>
            <strong>{{ this.note != null ? this.note : "-" }}</strong> <br />
            <b-button
              v-if="this.payment_status !== 'cancelled'"
              variant="danger"
              @click="cancelInvoice"
              >Cancel Invoice</b-button
            >
          </b-col>
          <b-col lg="4" sm="5" class="ml-auto">
            <!-- <table class="table table-clear">
              <tbody>
                <tr>
                  <td class="left"><strong>Pembelian Perhiasan</strong></td>
                  <td class="right">{{ this.total_price | currency }}</td>
                </tr>
                <tr>
                  <td class="left"><strong>Komisi</strong></td>
                  <td class="right">{{ this.commission | currency }}</td>
                </tr>
                <tr>
                  <td class="left"><strong>Total</strong></td>
                  <td class="right">
                    <strong>{{ this.total | currency }}</strong>
                  </td>
                </tr>
              </tbody>
            </table> -->
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
export default {
  name: "invoice",
  data() {
    return {
      note: "",
      total: "",
      users: "",
      price: "",
      status: "",
      quantity: "",
      product: "",
      brand: "",
      address: "",
      order_id: "",
      order_date: "",
      shipping_cost: "",
      payment_date: "",
      address_name: "",
      receiver_name: "",
      phone_number: "",
      total_price: "",
      total_amout: "",
      shipping_provider: "",
      payment_status: "",
      payment_name: "",
      tracking_no: "",
      commission: "",
      tax_ppn: '',
      tax_pph: '',
      amount_gold: '',
      amount_idr: '',
      province: "",
      city: "",
      district: "",
      admin_fee: "",
      village: "",
      postal_code: "",
      items: [],
      isLoading: false,
      modal: false,
      detailShipping: {
        provider: "",
        status: "",
        tracking_no: "",
        admin_fee: "",
        discount: "",
        shipping_cost: '',
        free_shipping: ''
      },
      errors: {
        code: "",
        message: "",
        status: "",
      },
    };
  },
  created() {
    this.$http
      .get(`jewelry-order-detail/` + this.$route.params.id)
      .then((result) => {
        let orders = result.data.orders;
        this.order_id = orders.order_id;
        this.order_date = orders.created_at;
        this.admin_fee = orders.insufficient === true ? orders.insufficient_fee : orders.admin_fee;
        this.payment_date = orders.payment_date;
        this.address_name = orders.address_name;
        this.receiver_name = orders.receiver_name;
        this.province = orders.province;
        this.postal_code = orders.postal_code;
        this.district = orders.district === null ? "-" : orders.district;
        this.village = orders.village === null ? "-" : orders.village;
        this.city = orders.city;
        this.phone_number = orders.handphone;
        this.address = orders.full_address;
        this.status = orders.status;
        this.shipping_cost = orders.shipping_cost;
        this.commission = result.data.commission;
        this.total = orders.insufficient === true ? orders.insufficient_total : result.data.total;
        this.note = result.data.note;
        this.users = orders.name;
        this.tax_ppn = orders.ppn;
        this.tax_pph = orders.pph;
        this.amount_gold = orders.insufficient === true ? orders.amount_gold + ' gram' : '-';
        this.amount_idr = orders.insufficient === true ? parseInt(orders.amount_idr) : '-';
        this.items = result.data.details;
        this.total_price = result.data.total_product;
        this.shipping_provider = orders.shipping_provider;
        this.payment_status = orders.payment_status;
        this.payment_name = orders.payment_name;
        this.tracking_no = orders.tracking_no;
      })
      .catch((error) => {
        if (error.response) {
          this.errors.code = error.response.status;
          this.errors.message = error.response.data.meta.message;
          this.errors.status = error.response.data.meta.code;
        }
      });
  },
  methods: {
    openModal(item) {
      this.modal = true;
      this.detailShipping.provider = item.shipping_provider.toUpperCase();
      this.detailShipping.status = item.status;
      this.detailShipping.tracking_no = item.tracking_no;
      this.detailShipping.admin_fee = item.admin_fee;
      this.detailShipping.discount = item.discount;
      this.detailShipping.shipping_cost = item.shipping_cost;
      this.detailShipping.free_shipping = item.free_shipping
    },
    setStatus(val) {
      if (val === "success" || val === "Success" || val === "paid") {
        return "Sudah Dibayar";
      } else if (val === "pending" || val === "Pending") {
        return "Menunggu Pembayaran";
      } else if (val === "processing" || val === "Processing") {
        return "Sedang Diproses";
      } else if (val === "cancelled" || val === "Cancelled") {
        return "Dibatalkan";
      } else if (val === "awaiting-shipment") {
        return "Dalam Pengiriman";
      } else if (val === "on-hold") {
        return "Ditunda";
      } else if (val === "trash") {
        return "Dibatalkan";
      }
    },
    cancelInvoice() {
      this.isLoading = true;
      this.$http
        .post(`jewelry-order/cancel/${this.$route.params.id}`)
        .then((res) => {
          this.isLoading = false;
          this.$swal.fire("Success!", res.data.data, "success").then(() => {
            location.reload();
          });
        })
        .catch((error) => {
          if (error.response) {
            this.isLoading = false;
          }
        });
    },
  },
};
</script>

<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
